import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { toast } from 'react-hot-toast'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { KTIcon } from 'src/_metronic/helpers'
import { QuestionBankAPI } from 'src/apis/question-bank'
import SAPPDialogButtonsCancelSubmit from 'src/common/SAPPDialogButtonsCancelSubmit'
import ButtonIcon from 'src/components/base/button/ButtonIcon'
import HookFormEditor from 'src/components/base/editor/HookFormEditor'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import { RESOURCE_LOCATION } from 'src/components/base/upload-file/ModalUploadFile/UploadFileInterface'
import UploadMulti from 'src/components/base/upload-file/UploadMulti'
import { PageLink } from 'src/constants'
import useChecked from 'src/hooks/use-checked'
import { useConfirm } from 'src/hooks/use-confirm'
import { ArrayFieldForm, IResponse, Role } from 'src/type'
import { IFile, ITopic, ITopicObject } from 'src/type/question-bank'
import ChooseQuestionType from '../choose-question-type/ChooseQuestionType'
import ImportQuestion from '../import-question/ImportQuestion'
import { IQuestion } from '../shared/interfaces'
import { useQuestionProviderContext } from '../shared/providers/QuestionProvider'
import './FormTopic.scss'
import ListQuestion from './ListQuestion'
import ListGrouping from 'src/components/base/list-grouping/ListGrouping'
import { uniqueId } from 'lodash'
import ButtonIconOnly from 'src/components/base/button/ButtonIconOnly'
import SappDrawer from 'src/components/base/SappDrawer'
import HookFormRadioGroup from 'src/components/base/radiobutton/HookFormRadioGroup'
import { topicValidationSchema } from 'src/utils/validation/topic-validation'
import { exhibitValidation } from 'src/utils/validation/exhibit-validation'
import { IExhibit } from 'src/type/exhibits'
import { DESCRIPTION_POPUPCONFIRM } from 'src/constants/lang'
import { useUserContext } from 'src/context/UserProvider'
import { CODE_ADMIN, TITLE_QUESTIONS_GR, TITLE_TOPIC_GR } from 'src/constants/permission'
import ButtonIconPrimary from 'src/components/ui/button-icon-primary/ButtonIconPrimary'

// import { useStore } from 'video-react';
export interface IListQuestionProps {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

interface IUploadFiles {
  file_name: string
  type: string
  resource_id: string
}

const defaultValues = {
  name: '',
  description: '',
}
const FormTopic = () => {
  const params = useParams()
  const location = useLocation()

  const {
    state: { multiple, constructed },
    refreshListQuestion,
  } = useQuestionProviderContext()

  const hookCheckedMultiple = useChecked<IQuestion>(multiple.listQuestion || [])
  const hookCheckedConstructed = useChecked<IQuestion>(constructed.listQuestion || [])

  const {
    checkedList: checkedListMultiple,
    listDataChecked: listDataCheckedMultiple,
    toggleCheckAll: toggleCheckAllMultiple,
  } = useMemo(() => hookCheckedMultiple, [hookCheckedMultiple])
  const {
    checkedList: checkedListConstructed,
    listDataChecked: listDataCheckedConstructed,
    toggleCheckAll: toggleCheckAllConstructed,
  } = useMemo(() => hookCheckedConstructed, [hookCheckedConstructed])

  const checkedList = useMemo(() => {
    return [...checkedListMultiple, ...checkedListConstructed]
  }, [checkedListMultiple, checkedListConstructed])

  const listDataChecked = useMemo(() => {
    return [...listDataCheckedMultiple, ...listDataCheckedConstructed]
  }, [listDataCheckedMultiple, listDataCheckedConstructed])

  const [openImportQuestionModal, setOpenImportQuestionModal] = useState<boolean>(false)
  const [openChooseQuestionTypeModal, setOpenChooseQuestionTypeModal] = useState<boolean>(false)
  const { confirm, contextHolder } = useConfirm()
  const [defaultEditor, setDefaultEditor] = useState<any>()
  const [defaultExhibitEditor, setDefaultExhibitEditor] = useState<any>()
  const [loading, setLoading] = useState<boolean>(false)
  const [uploadFiles, setUploadFiles] = useState<IUploadFiles[]>([])
  const { profileMe } = useUserContext()
  const hasPermission = (role: Role, permission: string) => role.permissions?.includes(permission)
  const allowRenderCreate = profileMe?.roles?.some(
    (role: Role) =>
      hasPermission(role, TITLE_TOPIC_GR.CREATE_QUESTION_TOPIC) ||
      role.code === CODE_ADMIN.SUPER_ADMIN
  )
  const allowRenderEdit = profileMe?.roles?.some(
    (role: Role) =>
      hasPermission(role, TITLE_TOPIC_GR.EDIT_QUESTION_TOPIC) ||
      hasPermission(role, TITLE_TOPIC_GR.CREATE_QUESTION_TOPIC) ||
      role.code === CODE_ADMIN.SUPER_ADMIN
  )
  const allowRenderRemove = profileMe?.roles?.some(
    (role: Role) =>
      hasPermission(role, TITLE_TOPIC_GR.REMOVE_QUESTION_TOPIC) ||
      hasPermission(role, TITLE_TOPIC_GR.EDIT_QUESTION_TOPIC) ||
      hasPermission(role, TITLE_QUESTIONS_GR.REMOVE_QUESTION) ||
      role.code === CODE_ADMIN.SUPER_ADMIN
  )
  const [loadingData, setLoadingData] = useState(true)
  const navigate = useNavigate()

  const useFormProp = useForm<ITopic>({
    resolver: zodResolver(topicValidationSchema),
    mode: 'onSubmit',
    defaultValues,
  })

  const { handleSubmit, control, setValue, watch, getValues } = useFormProp

  /**
   * @description state này để lưu data của file sau khi update từ editor
   */
  const [fileEditor, setFileEditor] = useState<Array<{ id: string }>>([])
  /**
   * @description state này để lấy id của file sau khi update từ editor
   */
  const textEditorFiles =
    fileEditor?.map((data) => {
      return { id: data?.id }
    }) || []

  const handleSetUploadFiles = (files: any) => {
    setUploadFiles(
      files.map((e: { id: string; name: string; resource_id: string; url: string }) => ({
        resource_id: e.resource_id || e.id,
        id: e.resource_id || e.id,
        name: e.name,
        type: 'attached',
        url: e.url,
      }))
    )
  }

  // handle exhibit
  const [formAddExhibit, setFormAddExhibit] = useState<ArrayFieldForm>({ open: false })

  const {
    append: appendExhibit,
    update: updateExhibit,
    remove: removeExhibit,
  } = useFieldArray({
    control,
    name: 'exhibits',
  })

  const setFilesFormAddNew = (files: any[]) => {
    setValueAddNewForm(
      'exhibit_files',
      files.map(
        (e: { id: string; name: string; resource_id: string; url: string; resource?: any }) => ({
          resource_id: e.resource_id || e.id,
          id: e.resource_id || e.id,
          name: e.name || e.resource?.name,
          type: 'attached',
          resource: { ...e, name: e.name || e.resource?.name },
        })
      )
    )
  }

  const useFormAddNew = useForm<IExhibit>({
    resolver: zodResolver(exhibitValidation),
    mode: 'onSubmit',
    defaultValues: {
      exhibit_name: '',
      exhibit_description: '',
      exhibit_type: 'TEXT',
    },
  })

  const {
    watch: watchAddNew,
    control: controlAddNew,
    handleSubmit: handleSubmitAddNew,
    setValue: setValueAddNewForm,
    formState: { errors: errorsAddNewForm },
    clearErrors: clearErrorsAddNewForm,
    reset: resetAddNew,
    setError,
  } = useFormAddNew

  const typeFormAddNew = watchAddNew('exhibit_type')
  const filesFormAddNew = watchAddNew('exhibit_files')

  const exhibits = watch('exhibits')

  const indexAddNewRef = useRef<number | undefined>()

  const handleAddNewOpen = (
    data?: {
      name: string
      files?: any[]
      type: 'TEXT' | 'FILE'
      description: string
      exhibit_name?: string
      exhibit_description?: string
      exhibit_type?: string
      exhibit_files?: any[]
    },
    id?: number
  ) => {
    indexAddNewRef.current = id
    if (data) {
      const uploadedFile = data.exhibit_files ?? data.files
      setValueAddNewForm('exhibit_name', data.exhibit_name ?? data.name)
      setValueAddNewForm(
        'exhibit_type',
        data.exhibit_description ? 'TEXT' : data.description ? 'TEXT' : 'FILE'
      )
      setValueAddNewForm('exhibit_description', data.exhibit_description ?? data.description)
      setDefaultExhibitEditor(data.exhibit_description ?? data.description)
      const files = uploadedFile?.map((file) => ({ ...file, name: file.resource.name }))
      setValueAddNewForm('exhibit_files', files)
    } else {
      setValueAddNewForm('exhibit_name', '')
      setValueAddNewForm('exhibit_type', 'TEXT')
      setValueAddNewForm('exhibit_description', '')
      setValueAddNewForm('exhibit_files', [])
      setDefaultExhibitEditor('')
    }
    setFormAddExhibit(() => ({ open: true, isEdit: !!data }))
    clearErrorsAddNewForm('exhibit_description')
    clearErrorsAddNewForm('exhibit_files')
  }

  const handleAddNewClose = () => {
    setFormAddExhibit((data) => ({ ...data, open: false }))
    setValueAddNewForm('exhibit_name', '')
    setValueAddNewForm('exhibit_type', 'TEXT')
    setValueAddNewForm('exhibit_description', '')
    setValueAddNewForm('exhibit_files', [])
    resetAddNew(
      {
        exhibit_name: '',
        exhibit_description: '',
        exhibit_type: 'TEXT',
      },
      {
        keepSubmitCount: false,
        keepIsSubmitted: false,
        keepErrors: false,
        keepValues: false,
        keepDirty: false,
        keepIsValid: false,
        keepTouched: false,
        keepDirtyValues: false,
        keepDefaultValues: false,
      }
    )
  }

  const onSubmitAddNew = (data: {
    exhibit_name: string
    exhibit_type: 'TEXT' | 'FILE'
    exhibit_description: string
    exhibit_files?: IFile[]
  }) => {
    if (data.exhibit_type === 'TEXT') {
      data.exhibit_files = []
    } else {
      data.exhibit_description = ''
    }
    if (filesFormAddNew && filesFormAddNew?.length > 1) {
      setError('exhibit_files' as any, { message: 'Only one File can be uploaded.' })
    } else {
      if (indexAddNewRef.current !== undefined) {
        updateExhibit(indexAddNewRef.current, {
          ...getValues(`exhibits.${indexAddNewRef.current}`),
          ...data,
        })
      } else {
        appendExhibit({ ...data, id: uniqueId('exhibit_') })
      }
      handleAddNewClose()
    }
  }

  const handleChangeAddNewType = () => {
    if (indexAddNewRef.current !== undefined) {
      setValueAddNewForm(
        'exhibit_description',
        getValues(`exhibits.${indexAddNewRef.current}.exhibit_description`)
      )
      setValueAddNewForm(
        'exhibit_files',
        getValues(`exhibits.${indexAddNewRef.current}.exhibit_files`)
      )
    } else {
      setValueAddNewForm('exhibit_description', '')
      setValueAddNewForm('exhibit_files', [])
    }
  }

  // end handle

  const loadData = async () => {
    if (params.id) {
      try {
        setLoading(true)
        const { data } = await QuestionBankAPI.getTopicById(params.id)
        if (data) {
          if (data.questions.find((q) => q?.is_single_question)) {
            navigate(`${PageLink.TOPICS}`)
            return
          }
          const dataDes = data.description
          setDefaultEditor(dataDes)
          setValue('name', data.name)
          setValue('description', dataDes)
          setValue('exhibits', data.exhibits)
          setUploadFiles(() =>
            data.files?.map((e: any) => ({
              resource_id: e.resource?.id,
              id: e.resource?.id,
              name: e.resource?.name,
              type: 'attached',
              url: e.resource?.url,
            }))
          )
        } else {
          // toast.error('Topic not found!')
          setTimeout(() => {
            navigate(`${PageLink.TOPICS}`)
          })
        }
      } catch (error: any) {
        if (allowRenderEdit) {
          toast.error(error.message || 'Topic not found!')
          setTimeout(() => {
            navigate(`${PageLink.TOPICS}`)
          })
        } else {
          setTimeout(() => {
            navigate(-1)
          })
        }
      } finally {
        setLoading(false)
      }
    }
    setLoadingData(false)
  }

  useLayoutEffect(() => {
    loadData()
  }, [params.id])

  const onSubmit = async (topic: ITopic, type?: 'add' | 'import') => {
    const saveTopic: ITopic = {
      name: (topic.name ?? '').trim(),
      description: (topic.description ?? '').trim(),
    }
    try {
      let response: IResponse<ITopicObject>
      const parseExhibits = exhibits?.map((exhibit) => {
        return {
          id: exhibit.id,
          name: exhibit.exhibit_name ?? exhibit.name,
          description: exhibit.exhibit_description ?? exhibit.description,
          files: exhibit.exhibit_files ?? exhibit.files,
        }
      })
      const requestParams = {
        ...saveTopic,
        display_type: 'VERTICAL',
        files: uploadFiles,
        exhibits: parseExhibits,
        text_editor_files: textEditorFiles,
      }

      if (params.id) {
        response = await QuestionBankAPI.editTopic({
          ...requestParams,
          id: params.id,
        })
      } else {
        response = await QuestionBankAPI.createTopic(requestParams)
      }

      if (response?.success) {
        setFileEditor([])
      }

      if (response) {
        params.id
          ? loadData()
          : // Use navigate to update the URL and params
            navigate(`${PageLink.TOPIC}/${response?.data?.id}${location.search || ''}`)
      }

      toast.success('Topic saved successfully!')
      if (params.id) {
        return
      }
      if (type === 'import') {
        setOpenImportQuestionModal(true)
      } else {
        setOpenChooseQuestionTypeModal(true)
      }
    } catch {
    } finally {
      setLoading(false)
    }
  }

  const handleCancel = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: [DESCRIPTION_POPUPCONFIRM],
      onClick: () => navigate(`${PageLink.TOPICS + (location.search || '')}`),
    })
  }

  const handleDeleteQuestions = async () => {
    if (!checkedList || checkedList.length <= 0) {
      return
    }
    setLoading(true)
    const toastId = uniqueId('delete_question')
    try {
      await QuestionBankAPI.deleteBulkQuestion(checkedList)
      toast.success('Questions deleted successfully!', { id: toastId })
      refreshListQuestion(['multiple', 'constructed'])
      toggleCheckAllConstructed(false)
      toggleCheckAllMultiple(false)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div>
      {contextHolder}
      <div className='card h-xl-100'>
        <div className='card-body border-0 d-block p-10 pb-0'>
          <div className='topic_name card-title d-flex flex-wrap mb-7 pb-1 w-100'>
            <HookFormTextField
              label='Item Set Name'
              required
              control={control}
              name='name'
              placeholder='Item Set name'
              skeleton={loading}
            ></HookFormTextField>
          </div>
          <div className='topic_description mb-7 pb-1'>
            <HookFormEditor
              label='Description'
              placeholder='Description'
              height={508}
              name='description'
              control={control}
              math={true}
              defaultValue={defaultEditor}
              skeleton={loading}
              resourceLocation={RESOURCE_LOCATION.QUESTION_TOPIC}
              object_id={params?.id ? params?.id : undefined}
              setDataFile={setFileEditor}
            />
          </div>

          <div className='mb-8'>
            <label className='d-flex align-items-center fs-6 fw-bold mb-5'>
              <span>Exhibit</span>
            </label>
            {!!exhibits?.length && (
              <div className='border rounded border-gray-300 mb-5'>
                <div className='p-5'>
                  {exhibits?.map((exhibit, i) => (
                    <div key={exhibit.id}>
                      <div className='d-flex flex-stack w-100'>
                        <div className='d-flex flex-stack flex-row-fluid d-grid gap-2'>
                          <div className='me-5'>
                            <span className='text-gray-700 fw-bold fs-6 sapp-text-truncate-1 text-break'>
                              {getValues(`exhibits.${i}.exhibit_name`) ??
                                getValues(`exhibits.${i}.name`)}
                            </span>
                          </div>
                          <div className='d-flex align-items-center'>
                            <div className='me-3'>
                              <ButtonIconOnly
                                iconName={'notepad-edit'}
                                activeColor='primary'
                                onClick={() => {
                                  setTimeout(() => {
                                    handleAddNewOpen(exhibit, i)
                                  })
                                }}
                              />
                            </div>
                            <div className='m-0'>
                              <ButtonIconOnly
                                iconName={'trash'}
                                activeColor='danger'
                                onClick={() => {
                                  setTimeout(() => {
                                    confirm({
                                      okButtonCaption: 'Yes',
                                      cancelButtonCaption: 'No',
                                      body: 'Bạn có chắc chắn muốn xóa không?',
                                      onClick: () => removeExhibit(i),
                                    })
                                  })
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      {exhibits?.length - 1 !== i && (
                        <div className='separator separator-dashed my-3'></div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            )}
            <div className='mb-5'>
              <div>
                <ButtonIcon
                  title={'Add more exhibit'}
                  className='h-45px d-flex justify-content-start align-items-center mt-xl-0 mt-4 w-100'
                  customButton='btn btn-outline btn-outline-dark btn-active-light-dark border-gray-300 text-gray-500 p-0'
                  type='button'
                  onClick={(e) => {
                    e?.target?.blur()
                    setTimeout(() => {
                      handleAddNewOpen()
                    })
                  }}
                >
                  <KTIcon iconName='plus' className='fs-2 text-gray-500 p-3' />
                </ButtonIcon>
              </div>
            </div>
          </div>
          <div className='topic_description w-100'>
            <UploadMulti
              fileList={uploadFiles}
              setFileList={handleSetUploadFiles}
              label='Resources'
              guideline={[
                'Định dạng cho phép pdf, docx, doc, xls, xlsx, csv, txt, ppt, pptx, zip. Kích thước tối đa 500MB.',
              ]}
              resourceLocation={RESOURCE_LOCATION.QUESTION_TOPIC}
              loading={loadingData}
              object_id={params?.id ? params?.id : undefined}
            />
          </div>
        </div>
        <div className='d-flex border-0 justify-content-end card-footer p-10'>
          <SAPPDialogButtonsCancelSubmit
            type='button'
            cancelClick={handleCancel}
            cancelButtonCaption='Cancel'
            okButtonCaption='Save'
            disabled={!allowRenderEdit || (!allowRenderCreate && !params.id)}
            okOnClick={() => {
              handleSubmit((data: any) => {
                setLoading(true)
                setTimeout(() => {
                  onSubmit(data)
                })
              })()
            }}
            className='justify-content-between d-flex'
            // classNameCancel='px-7 py-5 fs-4 lh-1 fw-bold me-3'
            // classNameSubmit='px-8 py-5 fs-4 lh-1 fw-bold'
            loading={loading}
          />
        </div>
      </div>
      {params.id && (
        <div className='card sapp-question-list mt-xl-8 mt-2 card mt-xl-5 mt-2 px-10 py-8'>
          <div className='card-toolbar justify-content-between d-flex align-items-center'>
            <div className='d-flex align-items-center justify-content-between'>
              <h3 className='card-title m-0 text-nowrap fw-bold me-5'>
                Questions List ({(multiple?.totalRecords || 0) + (constructed?.totalRecords || 0)})
              </h3>
            </div>
            {/* start:: button open modal */}
            <div className='d-flex flex-wrap justify-content-end gap-5'>
              {checkedList.length > 0 && allowRenderRemove ? (
                <ListGrouping
                  selected={checkedList}
                  okClick={handleDeleteQuestions}
                  title='Delete Selected'
                  body='Bạn có chắc chắn muốn xóa không?'
                  okButtonCaption='Yes'
                  isListScreen
                />
              ) : (
                <ButtonIconPrimary
                  title='Create Question'
                  iconName='plus'
                  onClick={() => setOpenChooseQuestionTypeModal(true)}
                  size='small'
                />
              )}
            </div>
          </div>

          <ListQuestion
            useCheckedMultiple={hookCheckedMultiple}
            useCheckedConstructed={hookCheckedConstructed}
          ></ListQuestion>
        </div>
      )}

      <ImportQuestion open={openImportQuestionModal} setOpen={setOpenImportQuestionModal} />
      <ChooseQuestionType
        open={openChooseQuestionTypeModal}
        setOpen={setOpenChooseQuestionTypeModal}
        isSingleQuestion={false}
      />
      <div>
        <SappDrawer
          rootClassName='sapp-question_essay-drawer'
          open={formAddExhibit.open}
          title={`${formAddExhibit.isEdit ? 'Edit' : 'Add More'} ${'Exhibit'}`}
          cancelButtonCaption={'Cancel'}
          okButtonCaption={'Save'}
          handleSubmit={handleSubmitAddNew(onSubmitAddNew)}
          handleClose={handleAddNewClose}
          width='50%'
          confirmOnclose
        >
          <div className='mb-8'>
            <HookFormTextField
              label={'Exhibit Name'}
              labelClass='d-flex align-items-center fs-6 fw-bold mb-5'
              required
              className='sapp-h-45px fs-6'
              control={controlAddNew}
              name='exhibit_name'
              placeholder={'Exhibit name'}
              guideline={[
                'Cho phép nhập chữ hoa, thường, chữ số và ký tự đặc biệt, giới hạn 1000 ký tự',
              ]}
            ></HookFormTextField>
          </div>
          <div className='mb-8'>
            <label className='d-flex align-items-center fs-6 fw-bold mb-5'>
              <span className='required'>Exhibit Type</span>
            </label>
            <div className='mb-10'>
              <HookFormRadioGroup
                direction='horizontal'
                separator={false}
                name='exhibit_type'
                control={controlAddNew}
                justify='start'
                gap={15}
                onChange={handleChangeAddNewType}
                labelClass='fw-semibold fs-6'
                options={[
                  {
                    label: 'Text',
                    value: 'TEXT',
                  },
                  {
                    label: 'Files',
                    value: 'FILE',
                  },
                ]}
              />
            </div>
            <div className='mb-8'>
              {typeFormAddNew === 'TEXT' && (
                <div>
                  <HookFormEditor
                    label='Description'
                    labelClass='d-flex align-items-center fs-6 fw-bold mb-5'
                    required
                    height={400}
                    name='exhibit_description'
                    control={controlAddNew}
                    className='w-100 fs-6'
                    math={true}
                    guideline={[
                      'Cho phép nhập chữ hoa, thường, chữ số, ký tự đặc biệt, nhập text, tạo table  theo các format trong texteditor',
                      'Cho phép chèn và upload link, ảnh, video, file ',
                    ]}
                    defaultValue={defaultExhibitEditor}
                    resourceLocation={RESOURCE_LOCATION.EXHIBIT}
                    object_id={params?.id ? params?.id : undefined}
                    setDataFile={setFileEditor}
                  />
                </div>
              )}
              {typeFormAddNew === 'FILE' && (
                <div>
                  <label className='d-flex align-items-center fs-6 fw-bold mb-5'></label>
                  <UploadMulti
                    fileList={filesFormAddNew}
                    setFileList={setFilesFormAddNew}
                    error={errorsAddNewForm.exhibit_files}
                    guideline={[
                      'Cho phép upload file PDF',
                      'Kích thước tập tin tải lên tối đa là 500MB.',
                    ]}
                    resourceLocation={RESOURCE_LOCATION.EXHIBIT}
                    maxCount={1}
                    isMultiple={false}
                  />
                </div>
              )}
            </div>
          </div>
        </SappDrawer>
      </div>
    </div>
  )
}

export default FormTopic
